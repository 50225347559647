import React from "react";
import { Link } from "gatsby";
import style from "./tags.module.less";
import { useTranslation } from "react-i18next";

const TagCard = props => {
  const { i18n } = useTranslation("common");

  const { img, name, description, color, slug } = props;

  return (
    <Link
      className={style.tagCard}
      to={`/tags/${slug.current}/${i18n.language}`}
    >
      <div className={style.tagCard}>
        <div
          className={style.tagImg}
          style={{
            backgroundImage: `url(${img})`
          }}
        />
        <div className={style.pd20px}>
          <div className="textCenter">
            <h4 style={{ color: `${color}` }}>#{name}</h4>
          </div>
          <p>{description.substring(0, 110) + "..."}</p>
        </div>
      </div>
    </Link>
  );
};

export default TagCard;

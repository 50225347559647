import React from "react";
import { graphql } from "gatsby";
import { Layout, Row, Col } from "antd";
import Header from "../components/PageLayout/Header";

import SidebarWrapper from "../components/PageLayout/Sidebar";
import TagCard from "../components/TagCard";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";

const Tags = ({ data }) => {
  const keywords = data.sanityPage.ceo_tags.map(item => item.translate);

  const slug = "tags";

  const { i18n } = useTranslation("common");

  const MenuItems = data.allSanityMenu.nodes;

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <Header MenuItems={MenuItems} />

        <SEO
          lang={i18n.language}
          title={data.sanityPage.ceo_title.translate}
          description={data.sanityPage.ceo_description.translate}
          path={slug}
          keywords={keywords}
        />

        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1 className="titleSeparate">{data.sanityPage.title.translate}</h1>
          </div>

          <Row gutter={[30, 20]}>
            {data.allSanityCategory.edges.map(val => (
              <Col key={val.node.slug} xs={24} sm={24} md={12} lg={8}>
                <TagCard
                  img={val.node.icon.asset.fluid.src}
                  name={val.node.title.translate}
                  description={val.node.description.translate}
                  color={val.node.color.hex}
                  slug={val.node.slug}
                />
              </Col>
            ))}
          </Row>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const query = graphql`
  query AllTags($language: String) {
    allSanityMenu(filter: { disabled: { in: true } }, sort: { fields: indx }) {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }
    sanityPage(slug: { current: { eq: "/tags" } }) {
      title {
        translate(language: $language)
      }
      ceo_description {
        translate(language: $language)
      }
      ceo_tags {
        translate(language: $language)
      }
      ceo_title {
        translate(language: $language)
      }
    }
    allSanityCategory {
      edges {
        node {
          title {
            translate(language: $language)
          }
          description {
            translate(language: $language)
          }
          icon {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          color {
            hex
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

export default Tags;
